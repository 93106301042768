function AdminJobsCtrl($mdDialog, $filter, $scope, $log, jobTracker, User, userProfile, jsyaml, searchQuery) {
  this.deleting = {};
  this.deletionSuccess = {};
  this.deletionErr = {};

  this.numSamples = 0;

  this.jobInputQuery = null;
  this.searchConfigYaml = null;

  this.profile = userProfile;

  this.config = null;

  const keyLength = function (obj) {
    if (!obj) {
      return 0;
    }
    return Object.keys(obj).length;
  };

  this.keyLength = keyLength;

  this.jobs = {};
  this.jobList = [];
  this.jobQueries = [];
  this.sharedLength = [];
  this.userOwners = [];
  this.isPublic = [];

  // TODO: to do this right would need to grab all user ID's that are not our own, and make 1 request for all of them
  // for now we cheat with job.email, which is most often the same
  const _updateJobList = () => {
    jobTracker.initializeAsync().then(() => {
      this.jobs = jobTracker.jobs["all"];
      this.jobList = [];
      this.jobQueries = [];
      this.sharedLength = [];
      this.userOwners = [];
      this.isPublic = [];

      this.jobList = Object.keys(this.jobs)
        .filter((id) => !this.jobs[id].isDeleted())
        .sort((a, b) => {
          return new Date(parseInt(b.substr(0, 8), 16) * 1000) - new Date(parseInt(a.substr(0, 8), 16) * 1000);
        })
        .map((id) => {
          if (this.jobs[id].inputQueryConfig && this.jobs[id].inputQueryConfig.queryBody) {
            this.jobQueries.push(searchQuery.getQuery(this.jobs[id].inputQueryConfig.queryBody));
          } else {
            this.jobQueries.push(null);
          }

          this.sharedLength.push(keyLength(this.jobs[id].sharedWith));

          this.isPublic.push(this.jobs[id].isPublic());

          return this.jobs[id];
        });

      return this.jobList;
    });
  };

  this.getUser = (userId) => {

    return "A";
  };
  // Update the component any time the jobs list could change
  // TODO: simplify listeners
  const _setListener = () => {
    $scope.$on(jobTracker.jobUpdatedEvent, () => _updateJobList());
  };

  this.$onInit = () => {
    _updateJobList();
    _setListener();
  };

  //Memoized time getting function
  const attemptTime = {};
  this.getTime = (type, attempt, start, end) => {
    if (attemptTime[type] && attemptTime[type][attempt]) {
      return attemptTime[type][attempt];
    }

    if (!attemptTime[type]) {
      attemptTime[type] = {};
    }

    attemptTime[type][attempt] = $filter("msToTime")(new Date(end).getTime() - new Date(start).getTime());

    return attemptTime[type][attempt];
  };

  this.updating = {};
  this.updateSuccess = {};
  this.updateErr = {};

  const _updateJob = (job, index, prop, val) => {
    this.updating[job._id] = true;

    const updateProp = {};
    updateProp[prop] = val;

    jobTracker
      .patch(job, updateProp)
      .then(
        (updatedJob) => {
          this.sharedLength[index] = keyLength(updatedJob.sharedWith);
        },
        (err) => {
          this.updateErr[job._id] = err;
        }
      )
      .finally(() => {
        delete this.updating[job._Id];

        // Don't clear errors
        // TODO: handle clearing errors on click
      });
  };

  this.deleteJob = ($event, job, index) => {
    const confirm = $mdDialog
      .confirm()
      .title("Delete Job")
      .textContent(`Clicking "Delete" will remove all data associated with this job.`)
      .ariaLabel(`Delete this job: ${job.name}`)
      .targetEvent($event)
      .ok("Delete")
      .cancel("Cancel");

    $mdDialog.show(confirm)
      .then(() => {
        this.deleting[job._id] = true;

        // Notify consumers that the job deletion was handled here, to avoid
        // unnecessary dialogs.
        //this.onDeleting();

        $event.stopImmediatePropagation();
        job
          .$remove(
            (job) => {
              // This isn't strictly necessary; jobs.events.service should pick this up
              // It is more of a precaution against socket.io failure
              // In any case jobTracker additions are idempotent, so no big deal
              [this.err, job] = jobTracker.trackJobUpdate(job);

              this.deletionSuccess[job._id] = true;
              _updateJobList();

              // this.onDeleted();
            },
            (err) => {
              this.deletionErr[job._id] = err;
            }
          )
          .finally(() => {
            delete this.deleting[job._id];

            // Don't clear errors
            // TODO: handle clearing errors on click
          });
      }).catch((err) => {
        $log.error(err);
      });
  };

  this.showFullSearchConfig = ($event) => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog.show({
      parent: angular.element(document.querySelector("#deleted-popup")),
      template: `<md-dialog aria-label='Saved Query' flex='80' style='max-width:50%; max-height:70%'>
            <md-toolbar>
              <div class='md-toolbar-tools'>
                <h1>Saved Query</h1>
                <span flex></span>
                <md-button
                aria-label='Close' class='md-icon-button'
                ng-click='cancel()'>
                  <md-icon class='material-icons'>close</md-icon>
                </md-button>
              </div>
            </md-toolbar>
            <md-dialog-content>
              <div class='md-dialog-content' layout='column'>
                <div style='white-space: pre-wrap;'>{{inputQueryConfig | json}}<div>
              </div>
            </md-dialog-content>
          </md-dialog>
         `,
      locals: {
        inputQueryConfig: this.inputQueryConfig,
      },
      controller: function dialogController($scope, $mdDialog, inputQueryConfig) {
        $scope.inputQueryConfig = inputQueryConfig;

        $scope.cancel = $mdDialog.hide;
      },
    });
  };

  this.downloadYamlConfig = () => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
      encodeURIComponent(
        jsyaml.safeDump(this.config, {
          sortKeys: true,
          noRefs: true,
          noCompatMode: true,
        })
      )
    );
    element.setAttribute("download", `${this.config.name || this.config.assembly}.v${this.config.version}.yaml`);
    element.target = "_self"; //may be needed for firefox
    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const userQuery = User.getAllUsers();

  this.showUsers = (ev, job, index) => {
    $mdDialog
      .show({
        controller: DialogController,
        templateUrl: "jobs/jobs.share.modal.tpl.html",
        locals: { job, userQuery },
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        fullscreen: true,
        isolateScope: true,
      })
      .then((job) => {
        _updateJob(job, index, "sharedWith", job.sharedWith);
        _updateJobList();
      });
  };

  function DialogController($scope, $mdDialog, job, userQuery, userProfile) {
    $scope.loading = true;
    $scope.job = null;
    $scope.fuse_user_results = [];
    $scope.checkboxes_read = {};
    $scope.checkboxes_write = {};
    $scope.allRead = false;
    $scope.allWrite = false;

    let users = null;
    const user_query_partial = userQuery.$promise
      .then((u) => {
        users = u.filter((thisUser) => thisUser._id !== job.userId);
        const options = {
          includeScore: true,
          shouldSort: true,
          threshold: 0.5,
          useExtendedSearch: true,
          keys: ["email", "name"],
        };

        let allReadMaybe = true;
        let allWriteMaybe = true;

        for (let idx in users) {
          const user_id = users[idx]._id;
          const user_name = users[idx].name;

          if (!(user_id in job.sharedWith)) {
            allReadMaybe = false;
            allWriteMaybe = false;
            break;
          }

          if (job.sharedWith[user_id] !== 400 && job.sharedWith[user_id] !== 600) {
            console.error(`Unknown job sharing value for user ${user_name}`);
            allReadMaybe = false;
            allWriteMaybe = false;
            break;
          }

          if (job.sharedWith[user_id] === 400) {
            allWriteMaybe = false;
            continue;
          }
        }

        $scope.allRead = allReadMaybe;
        $scope.allWrite = allWriteMaybe;

        $scope.loading = false;
        $scope.fuse_user_results = users;

        return new Fuse(u, options);
      })
      .catch((err) => {
        $log.error(err);
        $scope.userFetchError = err;
        $scope.loading = false;
        $scope.fuse_user_results = [];
      });

    if (!("sharedWith" in job)) {
      job["sharedWith"] = {};
    } else {
      for (let userId in job.sharedWith) {
        if (job.sharedWith[userId] == 400) {
          $scope.checkboxes_read[userId] = true;
          $scope.checkboxes_write[userId] = false;
        } else if (job.sharedWith[userId] == 600) {
          $scope.checkboxes_read[userId] = true;
          $scope.checkboxes_write[userId] = true;
        } else {
          console.error(`Unknown scope value ${job.sharedWith[userId]}`);
        }
      }
    }

    $scope.job = job;

    $scope.onCancelled = () => {
      $mdDialog.cancel();
    };

    $scope.onSelected = () => {
      $mdDialog.hide($scope.job);
    };

    $scope.querySearch = (query) => {
      if (!query) {
        $scope.fuse_user_results = users;
        return users;
      }
      return user_query_partial.then((fuse_users) => {
        const s = fuse_users.search(query);

        $scope.fuse_user_results = [];
        s.forEach((item) => {
          if (item.item._id === job.userId) {
            return;
          }

          $scope.fuse_user_results.push({
            name: item.item.name,
            _id: item.item._id,
            email: item.item.email,
          });
        });
      });
    };

    $scope.updatePermissions = (user, permission_value, type) => {
      if (!(type === "read" || type === "write")) {
        console.error("only accept types 'read' and 'write'");
        return;
      }

      let value = 0;
      if (permission_value === true) {
        if (type === "read") {
          value = 400;
          $scope.checkboxes_read[user._id] = true;
          $scope.checkboxes_write[user._id] = false;
        } else if (type === "write") {
          value = 600;
          $scope.checkboxes_read[user._id] = true;
          $scope.checkboxes_write[user._id] = true;
        }
      }

      let current_value = job.sharedWith[user._id] || 0;

      if (permission_value === false) {
        if (type === "write") {
          if (current_value === 600) {
            value = 400;
            $scope.checkboxes_write[user._id] = false;
          }
        } else if (type === "read") {
          value = 0;
          $scope.checkboxes_read[user._id] = false;
          $scope.checkboxes_write[user._id] = false;
        }
      }
      if (!value) {
        delete job.sharedWith[user._id];
      } else {
        if (value < 400 || value > 600) {
          console.error(`${value} is an invalid value`);
        } else {
          job.sharedWith[user._id] = value;
        }
      }
    };

    $scope.updateAllPermissions = (type, permission_value) => {
      if (!(type === "read" || type === "write")) {
        console.error("only accept types 'read' and 'write'");
        return;
      }

      if (!(permission_value === true || permission_value === false)) {
        console.error("only accept permission_value 'true' and 'false'");
        return;
      }

      let value = 0;
      if (permission_value === true) {
        if (type === "read") {
          value = 400;
        } else if (type === "write") {
          value = 600;
          $scope.allRead = true;
          $scope.allWrite = true;
        }
      }

      if (permission_value === false) {
        if (type === "read") {
          $scope.allWrite = false;
          $scope.allRead = false;
        } else if (type === "write") {
          $scope.allRead = true;
          $scope.allWrite = false;
        }
      }

      for (let idx in users) {
        const user_id = users[idx]._id;
        let current_value = job.sharedWith[user_id] || 0;

        if (permission_value === true) {
          if (current_value < value) {
            job.sharedWith[user_id] = value;

            if (value === 600) {
              $scope.checkboxes_read[user_id] = true;
              $scope.checkboxes_write[user_id] = true;
            } else if (value === 400) {
              $scope.checkboxes_read[user_id] = true;
              $scope.checkboxes_write[user_id] = false;
            }
          }

          continue;
        }

        if (type === "write") {
          if (current_value === 600) {
            job.sharedWith[user_id] = 400;
            $scope.checkboxes_write[user_id] = false;
          }
        } else if (type === "read") {
          delete job.sharedWith[user_id];
          $scope.checkboxes_read[user_id] = false;
          $scope.checkboxes_write[user_id] = false;
        }
      }
    };
  }
}

angular
  .module("sq.admin.jobs.component", [
    "sq.user.model",
    "sq.jobs.tracker.service",
    "sq.jobs.events.service",
    "sq.user.profile.service",
    "sq.jobs.results.search.query.service",
  ])
  .component("sqAdminJobs", {
    templateUrl: "admin/admin.jobs.tpl.html",
    controller: AdminJobsCtrl,
    controllerAs: "$ctrl",
  });
